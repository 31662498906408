import React, { Component } from 'react';
import Title from '../components/title';
import Select from '../components/select';
import VideoBlock from '../components/videoBlock';
import Carousel from '../components/carousel';
import styles from '../styles/scss/pages/videos.module.scss';
import { map as _map, uniqBy as _uniqBy, sortBy as _sortBy, find as _find, groupBy as _groupBy } from 'lodash'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"
import { navigateTo } from 'gatsby-link'
import CustomModal from '../components/customModal';
import NewLayout from '../layout/newLayout';

export default class Videos extends Component {

    extractParams = (search) => {
        var params = {}

        if (search !== "") {
            params = JSON.parse('{"' + decodeURI(search.replace("?", "").replace(/&/g, "\",\"").replace(/=/g, "\":\"")) + '"}')
        }

        // get video from url
        const video = params.video && params.video !== "null" ? params.video : null

        return {
            video
        }
    }

    constructor(props, context) {
        super(props, context)

        // Get a unique array of products attached to all the videos
        var uniqueProducts = _uniqBy([].concat.apply([], _map(this.props.pageContext.videos, 'relatedProducts')), (item) => {
            return item.id
        })

        // Get distinct array of product functions, sorted by name and add an option for all
        const functionalities = _map(_sortBy(_uniqBy([].concat.apply([], _map(uniqueProducts, 'productFunctions')), (item) => {
            return item.id
        }), (item) => {
            return item.name
        }), (item) => {
            return {
                value: item.id,
                text: item.name
            }
        })
        functionalities.unshift({
            value: null,
            text: 'All Functionalities'
        })

        // Get distinct array of use locations, sorted by name and add an option for all
        const uselocations = _map(_sortBy(_uniqBy([].concat.apply([], _map(uniqueProducts, 'useLocations')), (item) => {
            return item.id
        }), (item) => {
            return item.name
        }), (item) => {
            return {
                value: item.id,
                text: item.name
            }
        })
        uselocations.unshift({
            value: null,
            text: 'All Places of Use'
        })

        // Group videos by category
        var filteredVideos = _map(_groupBy(props.pageContext.videos, (video) => {
            return video.category.id
        }), (value) => {
            return _uniqBy(value, (vid) => {
                return vid.id
            })
        })

        // extract params from url
        const { search } = props.location;
        const params = this.extractParams(search)

        this.state = {
            videos: props.pageContext.videos,
            filteredVideos: filteredVideos,
            functionalities: functionalities,
            uselocations: uselocations,
            selectedFunctionality: null,
            selectedUseLocation: null,
            video: _find(props.pageContext.videos, { id: params.video })
        }
    }

    selectFunctionality = (selected) => {
        let selectedFunctionality = this.state.selectedFunctionality
        selectedFunctionality = selected.value
        this.setState({
            selectedFunctionality
        }, () => {
            this.filterVideos()
        })
    }

    selectUseLocation = (selected) => {
        let selectedUseLocation = this.state.selectedUseLocation
        selectedUseLocation = selected.value
        this.setState({
            selectedUseLocation
        }, () => {
            this.filterVideos()
        })
    }

    filterVideos = () => {
        let filteredVideos = this.state.videos

        filteredVideos = filteredVideos.filter((video) => {

            var filteredProducts = video.relatedProducts.filter((product) => {
                var matchFunction = true
                var matchUseLocation = true

                if (this.state.selectedFunctionality !== null) {
                    matchFunction = _find(product.productFunctions, (func) => {
                        return func.id === this.state.selectedFunctionality
                    }) !== undefined
                }

                if (this.state.selectedUseLocation !== null) {
                    matchUseLocation = _find(product.useLocations, (useLoc) => {
                        return useLoc.id === this.state.selectedUseLocation
                    }) !== undefined
                }

                return matchFunction && matchUseLocation
            })

            return filteredProducts.length > 0

        })

        // Group videos by category
        filteredVideos = _map(_groupBy(filteredVideos, (video) => {
            return video.category.id
        }), (group) => {
            return _uniqBy(group, (vid) => {
                return vid.id
            })
        })

        this.setState({
            filteredVideos
        })
    }

    selectVideo = (videoId) => {
        this.go(videoId)
    }

    go = (video) => {
        const { pathname } = this.props.location
        var params = []
        if (video) {
            params.push(`video=${video}`)
        }
        return navigateTo(`${pathname}` + (params.length > 0 ? "?" : "") + params.join('&'))
    }

    render() {

        var playing = false

        let filteredVideos = _map(this.state.filteredVideos, (group, g) => {
            return _map(group, (vid, i) => {
                if (!playing && vid.id === this.state.video) {
                    vid.play = true
                    playing = true
                }
                return vid
            })
        })

        return (
            <NewLayout>
                {this.props.pageContext.page.seo.title && <h1 style={{ display: 'none' }}>{this.props.pageContext.page.seo.title}</h1>}
                <SEO page={this.props.pageContext.page} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={styles.container}>
                        <PageContents content={this.props.pageContext.page.content} />
                        <div className={styles.selectOptions}>
                            <Title value='Filter By:' gray size={15} style={{ textAlign: 'right', alignSelf: 'center' }} />
                            <Select placeholder='Functionality' values={this.state.functionalities} onChange={this.selectFunctionality} />
                            <Select placeholder='Where to use' values={this.state.uselocations} onChange={this.selectUseLocation} />
                        </div>
                        <div className={styles.listItems}>
                            {filteredVideos.map((group, g) => (
                                <React.Fragment key={group[0].category.id}>
                                    <Title value={group[0].category.name} alignLeft />
                                    <div className={styles.carouselItems}>
                                        <Carousel slidesToShow={group.length < 3 ? group.length : 3}>
                                            {group.map((video, i) => (
                                                <VideoBlock
                                                    key={video.id}
                                                    id={video.id}
                                                    youtubeVideoId={video.video.providerUid}
                                                    image={video.video.thumbnailUrl}
                                                    title={video.title}
                                                    description={video.teaser}
                                                    onPlay={this.selectVideo}
                                                    containerClass={styles.videoBlockCarousel}
                                                />
                                            ))}
                                        </Carousel>
                                    </div>
                                </React.Fragment>
                            ))}

                            {this.state.video && (
                                <CustomModal
                                    rel={0}
                                    youtubeVideoId={this.state.video.video.providerUid}
                                    openVideo={this.state.video}
                                    setOpenVideo={(status) => {
                                        this.setState({ video: status })
                                        this.go(status)
                                    }}
                                    classNames={{ modal: styles.customModal }}
                                    center/>
                            )}

                        </div>
                    </div>
                </div>
            </NewLayout>
        );
    }
}
