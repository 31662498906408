import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from '../styles/scss/components/carousel.module.scss'

function PrevArrow(props) {
    const { onClick, responsive } = props;
    return (
        <div tabIndex={0} role = "button" className={styles.leftArrow} onClick={onClick} onKeyDown={onClick}>
            <i className={`fa fa-angle-${responsive ? 'up' : 'left'}`} />
        </div>
    );
}
function NextArrow(props) {
    const { onClick, responsive } = props;
    return (
        <div role = "button" tabIndex={0} className={styles.nextArrow} onClick={onClick} onKeyDown={onClick}>
            <i className={`fa fa-angle-${responsive ? 'down' : 'right'}`} />
        </div>
    );
}
export default class Index extends Component {
    render() {
        const { children } = this.props;
        return (
            <>
                <Slider
                    className={styles.sliderDesktop}
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    infinite
                    speed={200}
                    slidesToShow={this.props.slidesToShow}
                    slidesToScroll={1}>
                    {children}
                </Slider>
                <Slider
                    className={styles.sliderMobile}
                    nextArrow={<NextArrow responsive />}
                    prevArrow={<PrevArrow responsive />}
                    infinite
                    verticalSwiping
                    vertical
                    speed={200}
                    slidesToShow={1}
                    slidesToScroll={1}>
                    {children}
                </Slider>
            </>

        );
    }
}
